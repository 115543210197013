import { is, Dictionary } from 'ramda'
import { RawLocation, Route, Location } from 'vue-router'
import { nonCategories } from './categories'

export const useRewrite = (categories: Dictionary<any> | null | undefined) => {
  const replaceMatch = (router: any, ) => {
    const oldMatch = router.match
    router.match = (raw: RawLocation, current: Route, redirectedFrom: Location) => {
      const newRaw = modifyRawLocation(raw)
      const matchedRoute = oldMatch.call(router, newRaw, current, redirectedFrom)
      return modifyRoute(matchedRoute)
    }
  }

  const modifyUrl = (raw: string) => {
    const url = new URL(raw, 'http://localhost')
    const newPath = modifyPath({
      path: url.pathname,
      hash: url.hash
    })
    return newPath.path + url.search + (newPath.hash ?? '')
  }

  const modifyPath = (raw: Location): Location => {
    if (!raw.path || raw.path === '/')
      return raw

    const path = raw.path.substring(1)
    const spl = path.split('/')
    // if (nonPages.includes(spl[0]))
    //   return raw

    /* if (spl[0] === 'checkout') {
      if (process.client) {
        if (spl.length === 1) {
          const hash = (raw.hash && raw.hash !== '')
            ? raw.hash
            : '#delivery'

          const hashSpl = hash.replace(/^#/, '').split('/')
          let pathPart
          let hashPart
          if (hashSpl.length > 1) {
            pathPart = hashSpl[0]
            hashPart = hashSpl[1]
            if (/^\s*$/.test(hashPart)) {
              hashPart = null
            }
          } else {
            pathPart = hashSpl[0]
            hashPart = null
          }

          // Modify for checkout hash
          if (hashPart) {
            return { path: `/checkout/${pathPart}`, hash: `#${hashPart}` }
          } else {
            return { path: `/checkout/${pathPart}` }
          }
        }
      }
    } else  */if (!nonCategories.includes(path) && categories?.[spl[0]]) {
      // Modify for PLP
      return { path: `/producten/${path}`, hash: raw.hash }
    } else if (spl.length > 1 && spl[1] === 'product') {
      // Modify for PDP
      if (spl.length > 2) {
        return { path: `/product/${encodeURIComponent(spl[0])}-${spl[2]}`, hash: raw.hash }
      } else {
        return { path: `/product/${encodeURIComponent(spl[0])}`, hash: raw.hash }
      }
    }

    return raw
  }

  const modifyRawLocation = (raw: RawLocation) => {
    if (is(String, raw)) {
      return modifyUrl(raw)
    } else {
      if (!raw.path)
        return raw

      const newPath = modifyPath(raw)
      raw.path = newPath.path
      raw.hash = newPath.hash
      return raw
    }
  }

  const cloneRoute = (route: Route, newFullPath: string) => {
    const url = new URL(newFullPath, 'http://localhost')
    let pathname = url.pathname
    if (pathname !== '/' && /\/$/.test(pathname)) {
      pathname = pathname.substring(0, pathname.length - 1)
    }

    const newRoute: Route = {
      name: route.name,
      meta: route.meta,
      path: route.path,
      hash: route.hash,
      query: route.query,
      params: route.params,
      fullPath: pathname.toLowerCase() + url.search + url.hash,
      matched: route.matched,
      redirectedFrom: route.redirectedFrom
    }
    Object.freeze(newRoute)
    return newRoute
  }

  const modifyRoute = (route: Route) => {
    if (!route)
      return route

    const routeName = route.name?.split('__')[0]
    if (!routeName)
      return route

    if (routeName === 'product-lister-all') {
      // Modify for PLP
      const url = new URL(route.fullPath, 'http://localhost')
      const newFullPath = '/' + route.params.pathMatch + url.search + url.hash
      return cloneRoute(route, newFullPath)
    } else if (routeName === 'product-detail-url') {
      // Modify for PDP
      const url = new URL(route.fullPath, 'http://localhost')
      const productSlug = route.params.url
      if (productSlug.includes('%2Fproduct%2F')) {
        const newFullPath = decodeURIComponent(productSlug)
        return cloneRoute(route, newFullPath)
      } else if (productSlug.includes('/product/')) {
        return cloneRoute(route, productSlug)
      } else {
        const productNumberIndex = productSlug.lastIndexOf('-')
        const productNumber = productSlug.substring(productNumberIndex + 1)
        const productUrl = productSlug.substring(0, productNumberIndex)
        const newFullPath = `/${productUrl}/product/${productNumber}` + url.search + url.hash
        return cloneRoute(route, newFullPath)
      }
    }/*  else if (routeName.indexOf('checkout-') === 0
      && !routeName.includes('checkout-redirect')
      && !routeName.includes('checkout-success')) {
      const url = new URL(route.fullPath, 'http://localhost')
      const spl =  url.pathname.replace(/^\//, '').split('/')
      const firstHash = spl[1]
      const secondHash = url.hash ? '/' + url.hash.replace(/^#/, '') : ''
      const newFullPath = `/checkout${url.search}#${firstHash}${secondHash}`
      return cloneRoute(route, newFullPath)
    } */

    return cloneRoute(route, route.fullPath)
  }

  return {
    replaceMatch,
    modifyUrl,
    modifyPath,
    modifyRawLocation,
    modifyRoute
  }
}
