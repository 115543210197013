import { AxiosInstance } from 'axios'
import { PaymentOptions/* , PaymentMethod */ } from '@/types/payment'
// import project from '~/project.config.js'

export default function (instance: AxiosInstance) {
  const base = 'api/aspos/payment'

  return {
    async getOptions(): Promise<PaymentOptions | null> {
      const { data } = await instance.get(`/${base}/payment-methods`)

      if (!data.Success) return null

      data.PaymentMethods.sort((a: any, b: any) => a.SortOrder - b.SortOrder)
      const options: PaymentOptions = {
        debug: data.IsTest,
        methods: data.PaymentMethods.map((method: any) => ({
          code: method.Code.toLowerCase(), // Map to inner code
          name: method.Description,
          pinCodeRequired: method.CustomerContactPinCodeRequired,
        })),
      }
      // const providerName = project.checkout?.payment?.provider ?? 'MultiSafepay2'
      // const providerName = 'MultiSafepay2';
      // const provider = data.Providers[providerName]
      // const ideal = provider?.ideal
      // if (ideal) {
      //   options.banks = Object.keys(ideal.Issuers).map<PaymentMethod>(
      //     (code) => ({
      //       code: ideal.Issuers[code].code,
      //       name: ideal.Issuers[code].name,
      //     })
      //   )
      // }

      return options
    },

    // async validatePinCode(pinCode: string): Promise<any> {
    //   const response: AxiosResponse = await instance.post(
    //     `/api/bouwmaat/payment/pin/validate`,
    //     {
    //       pinCode,
    //     }
    //   )
    //   return response.data?.Success
    // },
  }
}
