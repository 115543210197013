import { Dictionary, uniqBy } from 'ramda'
import { AxiosInstance, AxiosResponse } from 'axios'
import {
  Product,
  ProductConnectionType,
  ProductListOptions,
  ProductReview,
  ProductSelection,
  ProductStock,
  ProductSelectionRequest,
  ProductStockRefresh,
  GetProductParams,
} from '@/types/product'
import {
  convertProduct,
  convertProductAvailibility,
  convertProductReview,
  convertProducts,
  convertProductSelection,
  convertProductSelections,
  convertProductStock,
  convertStock,
} from '~/lib/api/deserializers/product'
import { convertWebNode } from '~/lib/api/deserializers/webNode'
import { ReviewSorting } from '~/composables/product/useProductReview'
import project from '~/project.config'

const VARIANT_CONNECTION_TYPES_SET = new Set(
  project.product.details.variantConnectionTypes
)
enum ReviewSortField {
  CustomerRating = 'CustomerRating',
  CreationDate = 'CreationDate',
}

const SORT_BY_PARAMS: Readonly<Record<
  ReviewSorting,
  {
    field: ReviewSortField
    ascending: boolean
  }
>> = {
  [ReviewSorting.NewestFirst]: {
    field: ReviewSortField.CreationDate,
    ascending: false,
  },
  [ReviewSorting.OldestFirst]: {
    field: ReviewSortField.CreationDate,
    ascending: true,
  },
  [ReviewSorting.MostStars]: {
    field: ReviewSortField.CustomerRating,
    ascending: false,
  },
  [ReviewSorting.FewestStars]: {
    field: ReviewSortField.CustomerRating,
    ascending: true,
  },
}

export default function (instance: AxiosInstance) {
  const base = 'api/aspos/products'

  return {
    async getListOptions(): Promise<ProductListOptions | null> {
      const response: AxiosResponse = await instance.get(
        `/${base}/elastic-search/config`
      )
      if (!response.data.Success) return null

      const data = response.data.Data

      const facetLabels = data.FacetTitleOverride
      const sortOptions = data.Sorting.map((item: any) => ({
        label: item.Title,
        field: item.FieldName,
      }))

      return {
        limit: data.MaximumProdCountToShowDedicatedCategoryPage || 12,
        facetLabels,
        sortOptions,
      }
    },

    async getByUrl(
      url: string,
      payload?: GetProductParams
    ): Promise<Product | null> {
      const defaultParams = {
        includingWebNode: true,
        realtimeStock: false,
      }
      const params = Object.assign(defaultParams, payload)
      const response: AxiosResponse = await instance.get(
        `/${base}/url/${encodeURIComponent(url)}`,
        {
          params,
        }
      )

      if (!response.data.Success) return null

      const product = convertProduct(response.data.Product, {
        url: response.data.ProductUrl,
        ...(response.data.ExtraFields ?? {}),
      })
      if (response.data.WebNode) {
        product.webNode = convertWebNode(response.data.WebNode)
      }

      return product
    },

    async getByIds(productIds: string): Promise<Product[] | []> {
      const response: AxiosResponse = await instance.get(
        `/${base}/ids?productIds=${productIds}`,
        {
          params: {
            realtimeStock: true,
            fetchExtraFields: true,
          },
        }
      )

      if (!response.data.Success) return []

      const products = convertProducts(
        response.data.Products,
        response.data.ProductUrls,
        response.data.ExtraFields
      )

      return products
    },

    async getByWebNodeId(webNodeId: number | string): Promise<Product[] | []> {
      const response: AxiosResponse = await instance.get(
        `/api/aspos/webnodes/${webNodeId}/products`,
        {
          params: {
            fetchExtraFields: true,
          },
        }
      )

      if (!response.data.Products?.length) return []

      const products = convertProducts(
        response.data.Products,
        response.data.ProductUrls || response.data.Urls,
        response.data.ExtraFields
      )

      return products
    },

    /*
     * Product connections
     */
    async getConnections(
      productId: number,
      connectionTypes: ProductConnectionType[],
      options?: {
        fetchExtraFields?: boolean
        realtimeStock?: boolean
        limit?: number
        getSecondLevel?: boolean
      }
    ): Promise<Dictionary<Product[]>> {
      const {
        fetchExtraFields = true,
        realtimeStock = true,
        limit,
        getSecondLevel = false,
      } = options ?? {}
      const params = {
        fetchExtraFields,
        realtimeStock,
        limit,
        productId,
        connectionTypes,
      }
      const { data } = getSecondLevel
        ? await instance.post(`/${base}/${productId}/connections`, {
            ...params,
            extendConnections: {
              sourceConnectionTypes: 'Color',
              sourceProductTypes: 'Variant',
              targetConnectionTypes: 'Size',
            },
          })
        : await instance.get(`/${base}/${productId}/connections`, {
            params,
          })
          
      if (!data.Success) return {}

      const groups: Dictionary<Product[]> =
        data.Connections?.reduce(
          (prev: Dictionary<Product[]>, current: Dictionary<any>) => {
            let group = prev[current.Type]
            if (!group) {
              prev[current.Type] = group = []
            }

            let tempProduct = current.Product
            if(project.product.useTopLevelImagesForVariants && current.ParentProduct){
              tempProduct.Images = current.ParentProduct.Images
            }
            if(project.product.useTopLevelMemoForVariants && current.ParentProduct){
              tempProduct.description = current.ParentProduct.description
            }
            if (current.ProductId === productId && current.ParentProduct) {
              tempProduct = current.ParentProduct
            }


            if (
              tempProduct.Id !== productId 
              && tempProduct.ActiveWebNodes?.length
            ) {
              const product = convertProduct(tempProduct, {
                url: data.ProductUrls?.[tempProduct.Id],
                ...(data.ExtraFields?.[tempProduct.Id] ?? {}),
              })
              const isVariantType = VARIANT_CONNECTION_TYPES_SET.has( // ['Size', 'Color', 'Variant'],
                current.Type
              )
              if (
                (isVariantType && !product.isVirtual) ||
                (!isVariantType && product.isVirtual)
              ) {
                group.push(product)
              }
            }

            return prev
          },
          {}
        ) ?? {}
      // unify each group by productId
      for (const key in groups) {
        groups[key] = uniqBy((product) => product.id, groups[key])
      }

      return groups
    },

    /*
     * Product reviews
     */

    async getReviews(payload: {
      productId: number
      sorting?: ReviewSorting
    }): Promise<ProductReview[]> {
      const { productId, sorting } = payload
      const sortBy = sorting && SORT_BY_PARAMS[sorting]
      const response: AxiosResponse = await instance.get(
        `/${base}/${productId}/reviews`,
        {
          params: {
            sortField: sortBy?.field,
            sortAscending: sortBy?.ascending,
          },
        }
      )

      if (!response.data.Success) return []

      return (
        response.data.Reviews?.map((review: any) =>
          convertProductReview(review)
        ) ?? []
      )
    },

    async addReview(
      productId: number,
      review: ProductReview,
      isVisible = false
    ): Promise<boolean> {
      let success = false
      try {
        const response: AxiosResponse = await instance.post(
          `/${base}/${productId}/reviews`,
          {
            customerRating: review.rating,
            content: review.content,
            isVisible,
          }
        )
        success = response.data.Success
      } catch {
        success = false
      }

      return success
    },

    /*
     * Viewed products
     */

    async addViewedProduct(
      productId: number,
      brandId?: number
    ): Promise<boolean> {
      const response: AxiosResponse = await instance.post(`/${base}/viewed`, {
        productId,
        brandId,
      })
      return response.data.Success
    },

    async getViewedProducts(): Promise<Product[]> {
      const response: AxiosResponse = await instance.get(`/${base}/viewed`, {
        params: {
          fetchExtraFields: true,
          realtimeStock: true,
        },
      })
      if (!response.data.Success) return []

      const data = response.data
      return convertProducts(data.Products, data.ProductUrls, data.ExtraFields)
    },

    /*
     * Product sections
     */

    async getProductSelections(
      payload: ProductSelectionRequest
    ): Promise<ProductSelection[]> {
      const defaultParams: ProductSelectionRequest = {
        limit: 10,
        offset: 0,
        fetchExtraFields: false,
      }
      const params = Object.assign(defaultParams, payload)

      const response: AxiosResponse = await instance.get(
        `/${base}/selections`,
        {
          params,
        }
      )
      if (!response.data.Success) return []

      const data = response.data
      return convertProductSelections(
        data.ProductSelections,
        data.ProductUrls,
        data.ExtraFields
      )
    },

    async addProductSelection(
      productId: number
    ): Promise<ProductSelection | null> {
      const response: AxiosResponse = await instance.post(
        `/${base}/selections`,
        {
          productId,
        }
      )
      if (!response.data.Success) return null
      return convertProductSelection(response.data.ProductSelection)
    },

    async removeProductSelection(productSelectionId: number): Promise<boolean> {
      const response: AxiosResponse = await instance.delete(
        `/${base}/selections/${productSelectionId}`
      )
      return !!response.data?.Success
    },

    async clearProductSelections(): Promise<boolean> {
      const response: AxiosResponse = await instance.post(
        `/${base}/selections/clear`
      )
      return !!response.data?.Success
    },

    /*
     * Product stocks
     */

    async getProductStocks(productId: number): Promise<ProductStock[]> {
      const response: AxiosResponse = await instance.get(
        `/${base}/${productId}/stock`
      )
      const stocks =
        response.data?.Stock?.map((stock: any) => convertStock(stock)) ?? []

      return stocks
    },

    async getStocks(productIds: number[]): Promise<Dictionary<ProductStockRefresh>> {
      const response: AxiosResponse = await instance.post(`/${base}/stock`, {
        ids: productIds,
        fetchExtraFields: true
      })

      const result: Dictionary<ProductStockRefresh> = {}
      Object.keys(response.data.Products).forEach(id => {
        const data = response.data.Products[id]
        result[id] = {
          stock: convertProductStock(data.Stock),
          availibility: convertProductAvailibility(data.Availability)
        }
      })

      return result
    },
  }
}
