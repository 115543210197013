import { AxiosInstance, AxiosResponse } from 'axios'
import {
  // ShippingMethod,
  ShippingMethodsResponse
} from '~/types/shipping'
import { convertPickupLocations } from '~/lib/api/deserializers/store'

export default function (instance: AxiosInstance) {
  const base = 'api/shipping/default'

  // const mapCountry = (dataCountry: any): TranssmartDeliveryMapCountry => ({
  //   countryCodes: dataCountry.CountryCodes,
  //   options: dataCountry.Options.map((dataOption: any) => ({
  //     optionId: dataOption.OptionId,
  //     deliveryType: dataOption.DeliveryType
  //   }))
  // })

  return {
    async checkout(): Promise<ShippingMethodsResponse | null> {
      const response = await instance.get(`/${base}/methods`)
      // if (!response.data.Success) return null


      return {
        countries: response.data?.Countries
        // countryCode: response.data.CountryCode,
        // id: response.data.ShippingCost,
        // methods: response.data.ShippingDeliveryType
      }
    },
    async getPickupLocationsByPostalCode(
      postalCode: string
    ): Promise<any | null> {
      try {
        const response: AxiosResponse = await instance.get(`/api/aspos/shipping/pickuplocations/${postalCode}`)
        if (!response) return []

        return convertPickupLocations({
          stores: response?.data,
        })

      } catch {
        return []
      }
    },
    
  }
}
