import { Dictionary } from 'ramda'
import axios from 'axios'
import { Context } from '@nuxt/types'

const expireTimeout = 15 * 60 * 1000
const retryInterval = 10 * 1000
let retryHandle: NodeJS.Timer | null = null
let expired = false
export const categories = {
  value: null
}
export const nonCategories = ['actie']

export const ensureCategories = async () => {
  await getCategories()
  if (categories.value && !expired)
    return

  retryHandle = setInterval(async () => {
    // eslint-disable-next-line no-console
    // console.log(`route-categories: retry fetch...`)
    await getCategories()
    if (categories.value && !expired) {
      clearInterval(retryHandle as NodeJS.Timer)
    }
  }, retryInterval)
}

export const getCategories = async (context?: Context) => {
  try {
    const domain = (process.client ? context?.$config.DOMAIN : process.env.API_ENDPOINT).trimEnd('/')
    const response = await axios.get(`${domain}/api/aspos/webnodes/main/root`)
    const webNodes = response.data.Data?.Children
    if (!webNodes) return

    expired = false
    categories.value = webNodes.reduce((result: Dictionary<number>, category: any) => {
      result[category.Url] = category.Id
      return result
    }, {} as Dictionary<number>)

    if (!process.client) {
      setTimeout(() => {
        expired = true
      }, expireTimeout)
    }

    // eslint-disable-next-line no-console
    // console.log(`route-categories: fetched ${webNodes.length} categories`)

    return categories.value
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`route-categories-error: ${error}`)
  }
}
